








































































































































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import DoctorChronoDialog from '@/components/modules/DoctorChronoDialog.vue';
import DoctorBio from '@/components/cards/DoctorBio.vue';

export default Vue.extend({
  name: 'SettingsDoctorCard',
  components: {DoctorBio, DoctorChronoDialog},
  props: {
    doctor: Object,
    doctorColor: String,
  },
  data() {
    return {
      appendIcon: undefined,
      doctorNameHint: undefined,
      doctorNameColor: undefined,
      doctorName: '',
      doctorBookingAvailability: '',
      doctorBookingAvailabilityColor: undefined,
      doctorBookingAvailabilityHing: undefined,
      doctorExternalId: undefined,
      enabled: null,
      event: undefined,
      isOpenedDialog: false,
      editBio: false,
      bookingAvailabilityItems: [
        { text: 'All', value: 'all' },
        { text: 'New Patients', value: 'new' },
        { text: 'Existing Patients', value: 'existing' },
        { text: 'None', value: 'none' },
      ]
    };
  },
  computed: mapState(['confirmation']),
  watch: {
    confirmation({ uuid, wasConfirmed }) {
      if (uuid===this.doctor.uuid && wasConfirmed !== null) {
        this.$store.dispatch('RESET_CONFIRMATION')
        if (wasConfirmed) this.deleteDoctor()
      }
    },
    doctorName() {
      if (this.doctorName === this.doctor.name) {
        this.$emit('set-allowed-close', true);
      } else {
        this.$emit('set-allowed-close', false);
        this.doctorNameColor = 'yellow darken-1';
        this.doctorNameHint = 'NOT SAVED';
      }
    },
  },
  created() {
    this.doctorName = this.doctor.name;
    this.enabled = this.doctor.is_enabled === 'yes';
    this.doctorExternalId = this.doctor.externalId;
    this.doctorBookingAvailability = this.doctor.booking_availability;
  },
  methods: {
    ...mapActions([
      'ASK_FOR_CONFIRMATION',
      'RESET_CONFIRMATION'
    ]),
    async removeTreatment(uuid) {
      const payload = {
        doctorId: this.doctor.uuid,
        status: 'remove',
        params: { treatment: uuid }
      };
      const success = await this.$store.dispatch('UPDATE_DOCTOR_TREATMENT', payload);
      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Removed treatment from practitioner.' });
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    },
    changeExternalId(event) {
      this.isOpenedDialog = true;
      this.event = event;
    },
    camelToUnderscore(key) {
      var result = key.replace( /([A-Z])/g, " $1" );
      return result.split(' ').join('_').toLowerCase();
    },
    async updateBookingAvailability() {
      const payload = {
        doctorId: this.doctor.uuid,
        params: {
          booking_availability: this.doctorBookingAvailability,
        }
      };

      const success = await this.$store.dispatch('UPDATE_DOCTOR_BOOKING_AVAILABILITY', payload);

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: "Doctor's availability for booking has been successfully updated" });
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: "Something went wrong, try again!" });
      }
    },
    async updateDoctorField(event, field) {
      const payload = {
        doctorId: this.doctor.uuid,
        params: {
          name: this.doctorName,
        }
      };

      const success = await this.$store.dispatch('UPDATE_DOCTOR_SETTINGS', payload);
      if (success) {
        // Adjust the hint message and color based on the field updated
        let hintMessage;
        switch (field) {
          case 'name':
            hintMessage = "Updated practitioner's name";
            break;
          case 'bio':
            hintMessage = "Updated practitioner's bio";
            break;
          case 'bioShort':
            hintMessage = "Updated practitioner's short bio";
            break;
          default:
            hintMessage = "Updated practitioner's information";
        }
        this[`doctor${field}Hint`] = hintMessage;
        this[`doctor${field}Color`] = 'green darken-1';
        this.doctor[field] = this.doctor[field];
        this.$emit('set-allowed-close', true);
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }

      setTimeout(() => {
        this.resetDoctorFieldInput(field);
      }, 1500);
    },


    async toggleDoctorStatus(enabled) {
      const payload = {
        status: enabled ? 'enable' : 'disable',
        doctorId: this.doctor.uuid,
      }
      await this.$store.dispatch('UPDATE_DOCTOR_STATUS', payload);
    },

    resetDoctorNameInput() {
      this.doctorNameHint = undefined;
      this.doctorNameColor = undefined;
      this.$eventBus.$emit('emit-error', { state: false, text: '' });
      this.$refs.doctorNameInput.blur();
    },

    showConfirmation() {
      this.ASK_FOR_CONFIRMATION({
        message: 'This practitioner will be deleted forever',
        uuid: this.doctor.uuid
      });
    },

    async deleteDoctor() {
      const payload = {
        doctorId: this.doctor.uuid,
        params: { user: this.$store.state.auth.user.uuid }
      }
      const success = await this.$store.dispatch('DELETE_DOCTOR', payload);
      if (success) {
        this.doctorName = this.doctor.name;
        this.enabled = this.doctor.is_enabled === 'yes';
        this.$eventBus.$emit('emit-success', { state: true, text: `Doctor ${this.doctorName} Deleted!` });
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }
    },
  }
})
